import React from 'react';
import { useEffect } from 'react';
import { Page } from '../../Page';
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';
import './style.css';

const Home = () => {

  useEffect(() => {
    // Create a new <style> element
    const styleElement = document.createElement('style');

    // Set the innerHTML of the <style> element to your CSS content
    styleElement.innerHTML = `body { background: #fff; color: #111; }`;

    // Append the <style> element to the <head> of the document
    document.head.appendChild(styleElement);

    // Cleanup function to remove the <style> element when the component unmounts
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

    return(
      <div className="front">
        
      <Page>
      <div className="hero-image-top"></div>
      <div className="container">
     
      <div className="cta">
      <h2>Maptian is a mapping and data visualization studio.</h2>
      <Button href="mailto:hello@maptian.com" size="large">Launch a project with Maptian &raquo;</Button>&nbsp;&nbsp;
      </div>

      <div className="services">
      <h3>Our Services</h3>
      <Row gutter={40}>
      <Col xs={12} sm={6}>
          <img className="service" src="https://maptian-media.s3.amazonaws.com/dome.png" />
          <h4>Data Apps</h4>
        </Col>
        <Col xs={12} sm={6}>
          <img className="service" src="https://maptian-media.s3.amazonaws.com/apps.png" />
          <h4>Mapping Apps</h4>
        </Col>
        <Col xs={12} sm={6}>
          <img className="service" src="https://maptian-media.s3.amazonaws.com/analytics.png" />
          <h4>Data Analytics</h4>
        </Col>
        <Col xs={12} sm={6}>
          <img className="service" src="https://maptian-media.s3.amazonaws.com/rover.png" />
          <h4>Rapid Prototyping</h4>
        </Col>
        <Col xs={12} sm={6}>
          <img className="service" src="https://maptian-media.s3.amazonaws.com/pipline.png" />
          <h4>Data Pipelines</h4>
        </Col>
        <Col xs={12} sm={6}>
          <img className="service" src="https://maptian-media.s3.amazonaws.com/user.png" />
          <h4>Data UX</h4>
        </Col>
        <Col xs={12} sm={6}>
          <img className="service" src="https://maptian-media.s3.amazonaws.com/cleaning.png" />
          <h4>Data Processing</h4>
        </Col>
        <Col xs={12} sm={6}>
          <img className="service" src="https://maptian-media.s3.amazonaws.com/design.png" />
          <h4>Data Design</h4>
        </Col>
      </Row>
      </div>

      <h3>Our Work</h3>
      <div className="our-work">
      <Row gutter={40}>
        <Col sm={8}>
          <Link to="/project/infant-mortality">
          <img src="https://maptian-media.s3.amazonaws.com/child-lives-thumb.png" />
          <h4>Infant Mortality</h4>
          </Link>
        </Col>
        <Col sm={8}>
        <Link to="/project/global-temperature">
          <img  src="https://maptian-media.s3.amazonaws.com/bread-thumb.png" />
          <h4>Near surface temperature</h4>
          </Link>
        </Col>
        <Col sm={8}>
        <Link to="/project/ocean-plastic">
          <img src="https://maptian-media.s3.amazonaws.com/plastic-thumb.png" />
          <h4>Ocean Plastic</h4>
          </Link>
        </Col>
      </Row>
      </div>

      {/*}
      <div className="work-with-us">
        <Row gutter={40}>
          
          
          <Col sm={18}>
          <img src="https://maptian-media.s3.amazonaws.com/stack.png" />
          </Col>
          <Col sm={6}>
            <h1>Akuko</h1>
            <p>We're building Akuko - the modern data publishing platform.</p>
            <Button href="https://akuko.io" size="large">Learn more &raquo;</Button>
          </Col>
        </Row>

      </div>
    {*/}


      </div>

      
      
      </Page>
      </div>
    )
  }
  
  export { Home }