import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Row, Col, Alert, Radio, Button, Tooltip } from 'antd';
import './style.css';
import { CaretRightOutlined, CaretLeftOutlined } from '@ant-design/icons';
import { Page } from '../../Page';
import icon from './icon.png';
import Markdown from 'react-markdown';
import './style.css';
import axios from 'axios';
import rehypeRaw from 'rehype-raw';

const Project = () => {
  const [text, setText] = useState();
  const [meta, setMeta] = useState();
  const params = useParams();
  const { id } = params;
  const markdownFilePath = `/content/project/${id}/index.md`;

  useEffect(() => {
    // Create a new <style> element
    const styleElement = document.createElement('style');

    // Set the innerHTML of the <style> element to your CSS content
    styleElement.innerHTML = `body { background: ${meta?.backgroundColor || '#fff'}; color: ${meta?.color || '#111' }; } `;

    // Append the <style> element to the <head> of the document
    document.head.appendChild(styleElement);

    // Cleanup function to remove the <style> element when the component unmounts
    return () => {
      document.head.removeChild(styleElement);
    };
  }, [id, meta]);

  useEffect(() => {
    window.scrollTo({
      top: 0
    });
  }, [id])

  useEffect(() => {
    document.title = `${meta?.pageTitle} — Maptian`;
  }, [id, meta])

  useEffect(() => {
    axios.get(markdownFilePath).then((res) => {
      const content = res.data.split('-->')
      const rawMeta = content[0].split('<--')
      const meta = JSON.parse(rawMeta[1]);
      setMeta(meta);
      setText(content[1] || res.data);
    }).catch((err) => {
      alert('Error loading content')
    })
  }, [id])

    return(
      <Page>
        <div className="container">

        
        <article>
        <Row gutter={40}>
          
            <Col lg={17}>
            <div className="project-pager">
          <Tooltip 
            title="Previous"
          >
            <Link 
            className="prev-btn"
            to={ meta?.prevProject }
            >
            <Button
             style={{ 
              color: meta?.color || '#eee'
            }}
            >
            <CaretLeftOutlined />
            </Button>
            </Link>
            </Tooltip>
            <Tooltip 
            title="Next"
          >
            <Link 
            className="next-btn"
            to={ meta?.nextProject }
            >
            <Button
            style={{ 
              color: meta?.color || '#eee'
            }}
            >
             <CaretRightOutlined />
            </Button>
            </Link>
            </Tooltip>
          </div>

              { text &&
            <Markdown
            rehypePlugins={[rehypeRaw]}
            >{ text }</Markdown>
              }

<div className="project-pager">
          <Tooltip 
            title="Previous"
          >
            <Link 
            className="prev-btn"
            to={ meta?.prevProject }
            >
            <Button
             style={{ 
              color: meta?.color || '#eee'
            }}
            >
            <CaretLeftOutlined />
            </Button>
            </Link>
            </Tooltip>
            <Tooltip 
            title="Next"
          >
            <Link 
            className="next-btn"
            to={ meta?.nextProject }
            >
            <Button
            style={{ 
              color: meta?.color || '#eee'
            }}
            >
             <CaretRightOutlined />
            </Button>
            </Link>
            </Tooltip>
          </div>
            </Col>
            <Col lg={7}>
              <div className="sidebar">
                <img src={meta?.socialImage} />
                <dl>
                  <dt>Technologies</dt>
                  <dd>{ meta?.technologies }</dd>
                  { meta?.client &&
                  <>
                  <dt>Client</dt>
                  <dd>{ meta?.client }</dd>
                  </>
                  }
                  <dt>Project management</dt>
                  <dd>Maptian</dd>
                  <dt>Design</dt>
                  <dd>Dan McCarey</dd>
                  <dt>Development</dt>
                  <dd>Dan McCarey</dd>
                  { meta?.contact &&
                  <>
                  <dt>Point of contact</dt>
                  <dd>{ meta?.contact }</dd>
                  </>
                  }
                </dl>
             </div>
           </Col>
          </Row>
          </article>

          

        </div>
      </Page>
    )
  }
  
  export { Project }