import React from 'react';
import './style.css';

const Footer = () => {
    return(
      <div className="footer">
        
       <div className="legal">&copy; 2024 Maptian, LLC</div>
      </div>
    )
  }
  
  export { Footer }