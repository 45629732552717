import React from 'react';
import { useEffect } from 'react';
import { Page } from '../../Page';
import { Row, Col } from 'antd';
import BookCover from './book.png';
import './style.css';

const Books = () => {

  useEffect(() => {
    // Create a new <style> element
    const styleElement = document.createElement('style');

    // Set the innerHTML of the <style> element to your CSS content
    styleElement.innerHTML = `body { background: #fff; color: #111; }`;

    // Append the <style> element to the <head> of the document
    document.head.appendChild(styleElement);

    // Cleanup function to remove the <style> element when the component unmounts
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

    return(
      <Page>
      <div className="container">
      <div className="product-grid">
      <Row gutter={40}>
        <Col sm={12}>
        <h2>Grids</h2>
        <h3>Decoding the Geometry of Life</h3>
        <p>Read Buy Listen</p>
        </Col>
        <Col sm={12}>
            <div className="book">
                <img src={BookCover} />
            </div>
        </Col>
      </Row>
      </div>
      </div>
      </Page>
    )
  }
  
  export { Books }