// Import necessary dependencies
import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes, Outlet } from 'react-router-dom';
import { Home } from '../pages/Home/';
import { About } from '../pages/About/';
import { Project } from '../pages/Project/';
import { Books } from '../pages/Books/';
import './style.css';

const Contact = () => <h2>Contact</h2>;

const App = () => {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/project/:id" element={<Project />} />
          <Route path="/books" element={<Books />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />

          {/* A catch-all route for handling 404 errors */}
          <Route path="*" element={<h2>Page not found</h2>} />
        </Routes>
    </Router>
  );
};

export default App;
