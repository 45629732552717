import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Row, Col, Alert, Button } from 'antd';
import './style.css';
import { Page } from '../../Page';
import Markdown from 'react-markdown';
import './style.css';
import axios from 'axios';
import rehypeRaw from 'rehype-raw';

const About = () => {
  const [text, setText] = useState();
  const [meta, setMeta] = useState();
  const params = useParams();
  const { id } = params;
  const markdownFilePath = `/content/about/index.md`;

  useEffect(() => {
    // Create a new <style> element
    const styleElement = document.createElement('style');

    // Set the innerHTML of the <style> element to your CSS content
    styleElement.innerHTML = `body { background: ${meta?.backgroundColor || '#fff'}; color: ${meta?.color || '#111' }; } `;

    // Append the <style> element to the <head> of the document
    document.head.appendChild(styleElement);

    // Cleanup function to remove the <style> element when the component unmounts
    return () => {
      document.head.removeChild(styleElement);
    };
  }, [id, meta]);

  useEffect(() => {
    window.scrollTo({
      top: 0
    });
  }, [id])

  useEffect(() => {
    document.title = `${meta?.pageTitle} — Maptian`;
  }, [id, meta])

  useEffect(() => {
    axios.get(markdownFilePath).then((res) => {
      const content = res.data.split('-->')
      const rawMeta = content[0].split('<--')
      const meta = JSON.parse(rawMeta[1]);
      setMeta(meta);
      setText(content[1] || res.data);
    }).catch((err) => {
      alert('Error loading content')
    })
  }, [id])

    return(
      <Page>
        <div className="about">

      
        <article>
        <Row gutter={40}>
           <Col lg={2}>
           </Col>
            <Col lg={20}>
              { text &&
            <Markdown
            rehypePlugins={[rehypeRaw]}
            >{ text }</Markdown>
              }
            </Col>
          </Row>
          </article>

          
        </div>
      </Page>
    )
  }
  
  export { About }