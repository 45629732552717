import React from 'react';
import { Menu } from 'antd';
import { AppstoreOutlined, InfoCircleOutlined, BookOutlined, DownOutlined, CaretDownOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import './style.css';
import { Link } from 'react-router-dom';

const Header = () => {

const items = [
    {
        label: (<Link to="/">maptian</Link>),
        key: 'home',
        style: { fontFamily: "Lato", fontWeight: 700, marginTop: '-4px', fontSize: '24px' }
    },
    {
      label: (<Link to="/about">About</Link>),
      key: 'mail',
    },
    {
      label: (<>Our Work <CaretDownOutlined /></>),
      key: 'app',
      children: [
        {
          label: 'Academia',
          key: 'academia',
          children: [
            {
              label: (<Link to="/project/linked-data">Linked data</Link>),
              key: 'linked-data'
             }
          ]
        },
        { 
          label: 'Environment',
          key: 'environment',
          children: [
          {
              label: (<Link to="/project/global-temperature">Global temperature</Link>),
              key: 'temp'
          },{
              label: (<Link to="/project/ocean-plastic">Ocean plastic</Link>),
              key: 'natgeo'
          }
          ]
        },
        { 
          label: 'Government',
          key: 'government',
          children: [
            {
              label: (<Link to="/project/rule-of-law">Rule of law</Link>),
              key: 'roli'
          },{
              label: (<Link to="/project/access-to-justice">Access to justice</Link>),
              key: 'justice'
          },{
            label: (<Link to="/project/good-governance">Good governance</Link>),
            key: 'governance'
           },
          ]
        },
        {
          label: 'Health',
          key: 'health',
          children: [
          {
              label: (<Link to="/project/infant-mortality">Infant mortality</Link>),
              key: 'child-mortality'
          },
            {
              label: (<Link to="/project/road-deaths">Road deaths</Link>),
              key: 'road-deaths'
          }
          ]
        },
        { label: 'Transport',
          key: 'transport',
          children: [
            {
              label: (<Link to="/project/fleet-management">Fleet management</Link>),
              key: 'fleet-management'
             }
          ]
        }
      ]
    },

  ];

  return(
      <div className="header">
       <Menu mode="horizontal" items={items} />
      </div>
  )
}

export { Header }