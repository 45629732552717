import React, { useEffect } from 'react';
import { Header } from '../Header/'
import { Footer } from '../Footer/'
import './style.css';

const Page = (props) => {
    const { children } = props;

    useEffect(() => {
      document.title = 'Maptian'
    }, [])

    return(
      <div>
      <Header />
      <div className="page">
        { children }
      </div>
      <Footer />
      </div>
    )
  }
  
  export { Page }